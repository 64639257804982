
import axios from 'axios'

export default (store) => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      Authorization: 'Bearer ' + store.getters.getUserToken
    },
    withCredentials: true
  })
}
