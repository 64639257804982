<template>
    <v-app id="inspire">
        <v-navigation-drawer v-if="user"
            v-model="drawer"
            app
            dark
            src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        {{ shorterApplicationName }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
                dense
                nav
            >
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    :to="item.route"
                >
                    <v-list-item-icon :class="{'is-active': $route.name === item.title}">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app v-if="user">
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

                <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <!-- <GetToken
                  :userprop=this.user
                  @generateTokenSuccess=updateToken
                  /> -->
                <v-spacer></v-spacer>
                <p class="mb-0 mr-4"> Hi, {{ user.name }}</p>
                <a href="#" @click.prevent="logout"> Logout</a>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <router-view/>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { mapState } from 'vuex'
// import GetToken from './views/users/GetToken'

export default {
  data () {
    return {
      drawer: null,
      applicationName: 'Online Gaming - Marketing CRM',
      shorterApplicationName: 'Marketing CRM',
      items: [
        {
          title: 'Promotions',
          icon: 'mdi-ticket',
          route: { name: 'promotions.index' }
        },
        {
          title: 'Players',
          icon: 'mdi-account',
          route: { name: 'players.index' }
        },
        {
          title: 'Companies',
          icon: 'mdi-account',
          route: { name: 'companies.index' }
        }
      ]
    }
  },

  mounted () {
    this.updateAxiosConfig()
  },

  computed: {
    pageTitle () {
      if (this.$route.meta.displayName) {
        return this.$route.meta.displayName(this.$route)
      }
      return ''
    },
    ...mapState({
      user: state => state.Store.user
    })
  },

  methods: {
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    },

    updateToken (usr) {
      this.user.api_token = usr.api_token
    },

    updateAxiosConfig () {
      this.$axios.interceptors.request.use((config) => {
        const token = this.user.api_token
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }

        return config
      }, function (error) {
        // Do something with request error
        return Promise.reject(error)
      })
    }
  },

  components: {
    // GetToken
  }
}
</script>
