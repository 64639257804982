import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { Store } from './store'
import vuetify from './plugins/vuetify'
import dotProp from 'dot-prop'
import hljs from '@/assets/highlightjs/highlight.pack'
import axios from 'axios'

import '@/assets/highlightjs/atom-one-dark.css'

Vue.prototype.$dotProp = dotProp

Vue.directive('highlightjs', {
  deep: true,
  bind: function (el, binding) {
    // highlight all targets
    const targets = el.querySelectorAll('code')
    targets.forEach((target) => {
      // override this in case of binding
      if (binding.value) {
        target.textContent = binding.value
      }
      hljs.highlightBlock(target)
    })
  }
})

Vue.config.productionTip = false

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { Store },
  plugins: [createPersistedState()]
})

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
Vue.prototype.$axios = axios

router.beforeEach((to, from, next) => {
  if (to.name === 'login' && store.getters.isAuthenticated) {
    return next({ name: 'promotions.index' })
  } else if (to.name !== 'login' && !store.getters.isAuthenticated) {
    return next({ name: 'login' })
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data () {
    return {
      pageTitle: ''
    }
  }
}).$mount('#app')
