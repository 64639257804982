import httpClient from '@/httpClient'

export const Store = {
  state: {
    user: null
  },
  getters: {
    isAuthenticated (state) {
      return !!state.user
    },
    getUserToken (state) {
      if (state.user) {
        return state.user.api_token
      }
      return null
    }
  },
  mutations: {
    authenticateUser (state, user) {
      state.user = user
    },
    unAuthenticateUser (state) {
      state.user = null
    }
  },
  actions: {
    login ({ state, commit }, credentials) {
      return new Promise((resolve, reject) => {
        httpClient(this).post('login', credentials).then(response => {
          commit('authenticateUser', response.data)
          resolve()
        }).catch(() => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        })
      })
    },
    logout ({ state, commit }) {
      return new Promise((resolve, reject) => {
        httpClient(this).get('logout').then(response => {
          commit('unAuthenticateUser')
          resolve()
        })
      })
    }
  }
}
