import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'promotions.index' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/promotions',
    name: 'promotions.index',
    meta: {
      displayName: route => { return 'Promotions' }
    },
    component: () => import(/* webpackChunkName: "promotions.index" */ '../views/promotions/List.vue')
  },
  {
    path: '/promotions/new',
    name: 'promotions.create',
    meta: {
      displayName: route => { return 'New Promotion' }
    },
    component: () => import(/* webpackChunkName: "promotions.show" */ '../views/promotions/Create.vue')
  },
  {
    path: '/promotions/:promotion/:contractId?',
    name: 'promotions.show',
    meta: {
      displayName: route => { return 'Promotion Details' }
    },
    component: () => import(/* webpackChunkName: "promotions.show" */ '../views/promotions/Show.vue')
  },
  {
    path: '/promotions/:promotion/update',
    name: 'promotions.update',
    meta: {
      displayName: route => { return 'Promotion Edit' }
    },
    component: () => import(/* webpackChunkName: "promotions.show" */ '../views/promotions/Update.vue')
  },
  {
    path: '/players',
    name: 'players.index',
    meta: {
      displayName: route => { return 'Players' }
    },
    component: () => import(/* webpackChunkName: "clients.index" */ '../views/players/List.vue')
  },
  {
    path: '/players/:player/promotions',
    name: 'players.promotions',
    meta: {
      displayName: route => { return 'Player Promotions' }
    },
    component: () => import(/* webpackChunkName: "clients.index" */ '../views/players/Promotions.vue')
  },
  {
    path: '/players/:player/add-promotion',
    name: 'players.addPromotionPlayer',
    meta: {
      displayName: route => { return 'Add Player to Promotion' }
    },
    component: () => import(/* webpackChunkName: "clients.index" */ '../views/players/addPromotionPlayer.vue')
  },

  /*
  COMPANIES
  */
  {
    path: '/companies',
    name: 'companies.index',
    meta: {
      displayName: route => { return 'Companies' }
    },
    component: () => import(/* webpackChunkName: "companies.index" */ '../views/companies/List.vue')
  },
  {
    path: '/companies/new',
    name: 'companies.create',
    meta: {
      displayName: route => { return 'New Company' }
    },
    component: () => import(/* webpackChunkName: "companies.create" */ '../views/companies/Create.vue')
  },
  {
    path: '/companies/:contract',
    name: 'companies.show',
    meta: {
      displayName: route => { return 'Company Details' }
    },
    component: () => import(/* webpackChunkName: "companies.show" */ '../views/companies/Show.vue')
  },
  {
    path: '/companies/:contract/update',
    name: 'companies.update',
    meta: {
      displayName: route => { return 'Company Update' }
    },
    component: () => import(/* webpackChunkName: "companies.update" */ '../views/companies/Update.vue')
  },

  /*
  USERS
  */
  {
    path: '/users',
    name: 'users.index',
    meta: {
      displayName: route => { return 'Users' }
    },
    component: () => import(/* webpackChunkName: "companies.index" */ '../views/users/List.vue')
  },
  {
    path: '/users/:id',
    name: 'users.show',
    meta: {
      displayName: route => { return 'User Details' }
    },
    component: () => import(/* webpackChunkName: "companies.show" */ '../views/users/Show.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
